<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
  <div v-if="isSandbox && env !== 'staging'" style="top: 30px; width: 180px; padding-left: 20px; left: -60px; z-index: 10000000; transform: rotate(315deg); box-shadow: 10px 10px 1px #2222!important;" class="bg-danger text-light m-0 position-fixed d-block text-center">
    Sandbox
  </div>
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />

    <!-- Global modal -->
    <b-modal v-show="showLoader" size="md" id="tz-app-loader" centered hide-footer hide-header no-close-on-backdrop no-close-on-esc>
      <div class="d-flex flex-row justify-content-center align-items-center">
        <span class="text-primary">
          <loader-icon size="3x" class="spinner"></loader-icon>
        </span>
      </div>
    </b-modal>

    <b-sidebar
    id="tz-preview-json-data"
    :title="jsonData.title || $t('previewData')"
    backdrop-variant="dark"
    backdrop
    right
    @change="toggleSidebar"
    :visible="showJsonData"
    shadow
  >
    <div class="p-0">
      <json-view v-if="jsonData.data" :data="jsonData.data"></json-view>
    </div>
  </b-sidebar>


  </div>
</template>

<script>
import TzJsonViewer from "./views/components/tz-json-viewer/tz-json-viewer.vue";

import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import { BModal } from 'bootstrap-vue';
import { LoaderIcon } from "vue-feather-icons";
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import baseUrl from './services/baseUrl';

import { useWindowSize, useCssVar } from '@vueuse/core'
import commonApi from '@/services/api';

import store from '@/store'
import eventBus from "@/services/event-bus";
import constants from "@/services/constants";
import useJwt from '@/auth/jwt/useJwt'
import Utils from '@/utils/index'
import { initialAbility } from '@/libs/acl/config'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

let timeoutInMS = Utils.getLocal('tz-cortex-inactive-timer') || 1800000; // 30 minutes -> 30 * 60 * 1000
let timeoutId;

export default {
  components: {
    // BV Components
    BModal,
    TzJsonViewer,

    LoaderIcon,

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  data() {
    return {
      isLoginRoute: false,
      rToken: '',
      calledLogout: false,
      failedRefreshTrials: 0,
      refreshTokenHolder: null,
      expirationTime: '',
      refreshTime: '',

      userData:{},
      jsonData: {
        title: ''
      },
      env: baseUrl.envName,
      showJsonData: false,
      isSandbox: false,
      showLoader: false,
      isLoaderVisible: false
    };
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
     currentRoute(){
      if(this.$route.meta.stopMonitoring){
        this.stopRefreshTokenTimeout();
      }

      return false
    }
  },
  watch: {
    currentRoute(e){
      // console.log(e);
    },
    '$route': function(route) {
      if(route && route.name == 'auth-login'){
        this.isLoginRoute = true
      }else{
        this.isLoginRoute = false
      }
    }
  },
  created() {
   eventBus.$on('REQUEST_PENDING_INVOKE_LOADER', this.toggleLoader);
   eventBus.$on(constants.events.RENDER_JSON_DATA, this.parseJsonData);
   eventBus.$on(constants.events.LOG_OUT, this.handleLogout);
   eventBus.$on(constants.events.START_MONITORING_INACTIVITY, this.resetTimer);
   eventBus.$on(constants.events.USER_LOGGED_IN, this.processLoginInfo);
    // commonApi.getMp().then(res=>{
    //   if(res.success && res.data){
    //     if(res.data.orgAccount){
    //       this.userData = res.data.orgAccount;
    //       localStorage.setItem('tz-cortex-org-user',JSON.stringify(this.userData));
    //     }
    //   }
    // }).catch(err=>{
    //   console.log(err);
    // })
  },
  beforeMount(){
    let token = localStorage.getItem('tz-cortex-token')
    const path = location.path;
    // if(!token && !location.href.includes('/login') && !location.href.includes('/sign-up')){
    //   this.$router.replace({path: '/login'})
    // }
    var queryParams = new URLSearchParams(location.search);
    let lang = queryParams.get('lang') || localStorage.getItem('locale') || 'en';
    if(lang && lang.toLowerCase().includes('fr')){
      lang = 'fr';
    }else{
      lang =  'en';
    }
    localStorage.setItem('locale', lang)
    this.$i18n.locale = lang;
},
  mounted(){
    Utils.setLocal('tz-cortex-inactive-timer', timeoutInMS);
    let env = localStorage.getItem('tz-cortex-env') || 'production';
    if(env == 'sandbox'){
      this.isSandbox = true;
    }
    if(localStorage.getItem('locale')){
      this.setLocal(localStorage.getItem('locale'))
    }
    else if(navigator && navigator.language){
      if(navigator.language.substr(0,2).toLowerCase() == 'fr'){
        this.setLocal('fr');
      }else{
        this.setLocal('en');
      }
    }else{
      this.setLocal('en');
    }
    this.initInactivityMonitoring();
    this.rToken = localStorage.getItem('tz-cortex-refresh-token') || '';
    this.refreshTime = localStorage.getItem('tz-cortex-token-refresh-time');
    if(this.rToken && this.refreshTime){
      this.calculateRefreshTime(this.refreshTime);
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  methods: {
    retryRefreshToken(){
      setTimeout(()=>{
        this.refreshToken();
      },10000);
      this.failedRefreshTrials++;
    },
    refreshToken(){
      if(this.failedRefreshTrials >= 10) return;
      commonApi.refreshToken(this.rToken).then(res=>{
        if(res.success && res.data) {
          this.setToken(res.data.token, res.data.refreshToken, res.data.expiresIn);
          this.failedRefreshTrials = 0;
        }
        // console.log(res)
      }).catch(error=>{
        this.retryRefreshToken();
      })
    },

    setToken(token, refreshToken, expiresIn){
      useJwt.setToken(token);
      localStorage.setItem(
        'tz-cortex-token',
        JSON.stringify(token)
      );
      this.rToken = refreshToken;
      localStorage.setItem(
        'tz-cortex-refresh-token',
        JSON.stringify(refreshToken)
      );
      commonApi.setDynamicToken();
      this.refreshTime = Math.floor((((expiresIn * 95) / 100) * 1000) + new Date().getTime()); // add 95 % of expiration time to current time
      localStorage.setItem('tz-cortex-token-refresh-time', this.refreshTime);
      timeoutInMS = expiresIn * 1000;
      Utils.setLocal('tz-cortex-inactive-timer', timeoutInMS);
      this.calculateRefreshTime(this.refreshTime);
      this.resetTimer();
    },
    calculateRefreshTime(refreshTime = 1){
      try{
        if(!refreshTime){
          refreshTime = 1;
        }
        const currentTime = new Date().getTime();
        let timer = refreshTime - currentTime;
        if(timer < 1000){
          timer = 1000
        }
        this.stopRefreshTokenTimeout();
        this.refreshTokenHolder = setTimeout(this.refreshToken, timer);
      }catch(e){
        // console.log(e)
      }
    },
    processLoginInfo(data){
      this.setToken(data.token, data.refreshToken, data.expiresIn);
    },
    stopRefreshTokenTimeout(){
      clearTimeout(this.refreshTokenHolder);
    },
    handleInactive() {
      if(location.pathname == '/login' && this.calledLogout == true){
        return false;
      }
      // Here you want to logout a user and/or ping your token
      this.logout();
    },
    startMonitoring() {
      if(timeoutInMS > 2147483647) timeoutInMS = 2147483647;
        // setTimeout returns an ID (can be used to start or clear a timer)
        timeoutId = setTimeout(this.handleInactive, timeoutInMS);

    },
    resetTimer() {
        clearTimeout(timeoutId);
        this.startMonitoring();
    },
    initInactivityMonitoring(){
        document.addEventListener("keypress", this.resetTimer, false);
        document.addEventListener("mousemove", this.resetTimer, false);
        document.addEventListener("mousedown", this.resetTimer, false);
        document.addEventListener("touchmove", this.resetTimer, false); 
        this.startMonitoring();
    },
    handleLogout(){
      if(!this.isLoginRoute){
        this.logout();
      }
    },
    logout() {
      clearTimeout(timeoutId);

      commonApi.logout().then(res=>{
        if(location.pathname == '/login'){
          this.calledLogout = true;
        }
        if(res.success){
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // remove merchant data form localStorage
          localStorage.removeItem('merchant-info')

          localStorage.removeItem('tz-cortex-token')
          localStorage.removeItem('tz-cortex-refresh-token');
          localStorage.removeItem('tz-cortex-token-refresh-time');
          localStorage.removeItem('tz-cortex-org-user');
          localStorage.removeItem('tz-cortex-user');
          this.stopRefreshTokenTimeout();

          // Remove userData from localStorage
          localStorage.removeItem('userData')

          // Reset ability
          this.$ability.update(initialAbility);

          // const prefix = location.href.includes('staging') || location.href.includes('localhost') ? "https://staging-" : "https://";
        }
      }).catch(err=>{
        // this.$toast(this.$t('failedToLogoutTryAgain'))
      })

      if(!this.isLoginRoute){
        // location = location.origin + '/login';
        this.$router.replace('/login');
      }



      },
    toggleSidebar(e){
      this.showJsonData = e;
    },
    parseJsonData(data){
      this.jsonData = data;
      this.showJsonData = true;
    },
    setLocal(locale){
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
    },
      toggleLoader(show) {
      if(show) {
        /**
         * Prevent displaying multiple modals.
         */
        if(this.isLoaderVisible) return;
        /**
         * Display loader.
         */
        this.$bvModal.show('tz-app-loader');
        /**
         * Toggle view params;
         */
        this.isLoaderVisible = true;
        this.showLoader = true;
      } else {
        /**
         * Stop execution if loader is visible.
         */
        if(!this.isLoaderVisible) return;
        /**
         * Hide loader.
         */
        this.$bvModal.hide('tz-app-loader');
        document.activeElement.blur();
        /**
         * Toggle view params;
         */
        this.isLoaderVisible = false;
        this.showLoader = false;
      }
    }
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    var queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode')
    if (mode) {
      localStorage.setItem('Sanaga-skin', mode)
      skin.value = mode;

    }

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
<style lang="scss">
  #tz-app-loader * {
    background: transparent;
    box-shadow: none;
  }
  #tz-app-loader+.modal-backdrop {
    background: rgba(0, 0, 0, .3) !important;
  }
  #tz-preview-json-data{
    width: 100%;
    max-width: 500px !important;
  }
</style>
